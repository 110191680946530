import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import Ripple from '../fx/ripple/Ripple'
import chevron from '../../assets/media/right-chevron.svg'


const LinkContent = ({children}) => (
  <React.Fragment>
    <span>
      {children}
    </span>
  </React.Fragment>
);

const LinkIconContent = ({children, icon}) => (
  <React.Fragment>
    <img src={icon} aria-hidden="true" className="btn-icon e" />
    <span>
      {children}
    </span>
    <img src={icon} aria-hidden="true" className="btn-icon f" />
  </React.Fragment>
);


class Link extends React.Component {
  
  constructor(props) {
    super(props) 

    this.state = {
        AniLink: false,
        ripple: false
    }

    this.mount = false
  }

  componentDidUpdate() {
    const { device } = this.props

/*     
    if (device === 'desktop' && this.mount === false) {
      this.mount = true
      this.importAnilink()
    }
 */
    const { ripple } = this.state
    if (ripple) {
      /* this.setState({
        ripple: false
      }) */
    }
  }

/*   importAnilink() {
    // import Anilink and use it on this device
    import('gatsby-plugin-transition-link/AniLink')
    .then((AniLink) => {
        this.setState({
            AniLink: AniLink.default
        })
    })
    .catch((e) => console.log(e))
  }
 */
  buttonStartRipple = (e) => {
    const { button } = this.props
    const offset = e.target.getBoundingClientRect()

    if (button) {
      this.setState({
        ripple: {
          x: e.clientX - offset.left,
          y: e.clientY - offset.top
        }
      })
    }
  }
  
  render () {
    const { AniLink } = this.state
    const { path, key, button, simplebutton, className, children, ripple } = this.props

    const classes = []
    let icon = null
    let ContentComponent = LinkContent
    
    if (button) { 
      classes.push('button')
    }

    if (simplebutton) {
      classes.push('button') 
      classes.push('simple')
      icon = chevron
      ContentComponent = LinkIconContent
    }

    // wrap the link in a fragment
    // or a ripple component if 
    // the ripple prop was provided
    let LinkWrap = React.Fragment
    if (ripple || button) {
      LinkWrap = Ripple
    }

/*     if (AniLink) {
        // apply the updated link to the component state 
        return (
          <LinkWrap className={`${className}`}>
            <AniLink 
              cover 
              bg="#000000" 
              to={path} 
              href={path} 
              key={key} 
              className={`${classes.join(" ")} ${className}`} 
              duration={0.6} 
              direction="up"
            >
              <ContentComponent icon={icon}>
                  {children}
              </ContentComponent>
            </AniLink>
          </LinkWrap>
        )
    } */
    
    // no Anilink lib, return reg link
    return (
      <LinkWrap>
        <GatsbyLink 
          to={path} 
          href={path} 
          className={`${classes.join(" ")} ${className}`}
        >
          <ContentComponent icon={icon}>
            {children}
          </ContentComponent>
        </GatsbyLink>
      </LinkWrap>
    )
  }
};

export default Link;