import React from 'react';
import { Link } from 'gatsby';

const Logo = () => {
  return (
    <Link to="/" className={`logo`}>
      <svg
        width="100%"
        viewBox="0 0 612.35 138.74"
        style={{maxWidth: `300px`}}
      >
        <g>
          <path d="M0,14.38h9.01v27.11h13.94v6.68H0V14.38z" />
          <path
            d="M66.81,13.79c11.13,0,16.94,6.64,16.94,17.48c0,10.75-5.91,17.48-16.94,17.48c-11.04,0-16.94-6.73-16.94-17.48
                C49.87,20.43,55.67,13.79,66.81,13.79z M66.81,42.07c5.47,0,7.65-5.23,7.65-10.79c0-5.87-2.18-10.8-7.65-10.8
                c-5.48,0-7.65,4.93-7.65,10.8C59.16,36.84,61.33,42.07,66.81,42.07z"
          />
          <path
            d="M141.17,47.39c-2.57,0.63-5.42,1.36-10.02,1.36c-9,0-19.41-4.55-19.41-17.34c0-11.76,8.22-17.62,19.36-17.62
                c3.44,0,6.78,0.78,10.02,1.75l-0.73,7.31c-2.9-1.6-5.71-2.38-8.81-2.38c-6.39,0-10.55,4.65-10.55,10.94
                c0,6.63,4.7,10.65,11.28,10.65c2.95,0,5.95-0.77,8.42-1.74L141.17,47.39z"
          />
          <path d="M169.49,14.38h9V27.3h10.65V14.38h9.01v33.79h-9.01V33.69h-10.65v14.47h-9V14.38z" />
          <path
            d="M277.82,47.2c-5.71,1.45-9.96,1.55-13.07,1.55c-9,0-19.41-4.55-19.41-17.34c0-12.73,8.95-17.62,21.34-17.62
                c2.23,0,6.92,0.24,10.22,1.5l-0.73,7.36c-2.81-1.65-6.1-2.18-8.23-2.18c-7.31,0-13.32,3.05-13.32,10.94
                c0,6.63,4.7,10.65,11.28,10.65c1.21,0,2.36-0.2,3.19-0.58V34.8h-5.28v-6.39h13.98V47.2z"
          />
          <path
            d="M318.54,14.38h11.04l13.02,33.79h-9.88l-2.52-7.21h-12.69l-2.65,7.21h-9.15L318.54,14.38z M324.06,21.35h-0.1l-4.36,13.21
                h8.47L324.06,21.35z"
          />
          <path d="M370.34,26.95h9v27.11h13.95v6.68h-22.95V26.95z" />
          <path d="M444.41,33.78h-9V6.68h-13.95V0h22.95V33.78z" />
          <path d="M472.34,14.38h23.82v6.39h-14.81v6.97h13.99v6.39h-13.99v7.65h15.35v6.39h-24.35V14.38z" />
          <path
            d="M526.12,14.38h16.07c6.01,0,10.9,2.71,10.9,8.52c0,4.5-2.13,7.5-6.49,8.52v0.1c2.91,0.77,3.1,3.34,4.79,7.45l3.3,9.2h-9.39
                l-1.94-6.1c-1.84-5.81-3.01-7.26-6.34-7.26h-1.88v13.36h-9.01V14.38z M535.13,28.42h2.37c2.9,0,6.58-0.1,6.58-3.93
                c0-3.14-3.14-3.73-6.58-3.73h-2.37V28.42z"
          />
          <path d="M590.95,35.44l-12.49-21.06h10.36l6.63,13.55l6.54-13.55h10.36l-12.4,21.06v12.73h-9V35.44z" />
          <path d="M245.16,95.56h2.91v30.04h10.65v2.72h-13.56V95.56z" />
          <path
            d="M264.5,117.25c0,8.87,5.68,8.87,6.76,8.87c1.55,0,3.8-0.75,5.16-1.97l0.14,3.14c-1.13,0.61-2.72,1.36-5.58,1.36
                c-9.39,0-9.39-9.9-9.39-12.3c0-9.01,4.46-12.3,8.5-12.3c6.95,0,7.65,7.37,7.65,13.19H264.5z M274.82,114.71
                c0-6.34-2.68-8.12-4.88-8.12c-3.71,0-5.44,5.02-5.44,8.12H274.82z"
          />
          <path
            d="M286.79,98.75v5.63h4.88v2.53h-4.88v15.58c0,1.92,0.33,3.61,2.63,3.61c1.27,0,1.74-0.28,2.25-0.52v2.72
                c-0.66,0.23-1.88,0.33-3.14,0.33c-3.52,0-4.46-2.72-4.46-5.4v-16.33h-3.57v-2.53h3.57V99.6L286.79,98.75z"
          />
          <path
            d="M321.71,122.97c0,1.78,0.14,3.57,0.28,5.35h-2.44l-0.19-3.43h-0.09c-1.64,2.63-3.61,3.75-6.71,3.75
                c-3.71,0-6.66-3.05-6.66-6.95c0-7.65,8.12-7.79,11.55-7.79h1.74c0-3,0-7.32-4.98-7.32c-3.19,0-5.73,2.25-6.01,2.49l-0.42-2.91
                c2.25-1.22,3.94-2.11,6.57-2.11c7.37,0,7.37,6.2,7.37,7.93V122.97z M319.17,116.26c-5.63,0-10.37,0-10.37,5.44
                c0,2.02,1.5,4.41,4.41,4.41c1.27,0,5.96-0.52,5.96-7.04V116.26z"
          />
          <path
            d="M328,111.8c0-2.49-0.14-4.93-0.19-7.42h2.53c0.05,1.41,0.14,2.82,0.19,3.8h0.09c1.74-3.43,3.75-4.13,6.38-4.13v2.91
                c-4.32,0-6.29,1.88-6.29,6.29v15.07H328V111.8z"
          />
          <path
            d="M344.99,98.75v5.63h4.88v2.53h-4.88v15.58c0,1.92,0.33,3.61,2.63,3.61c1.27,0,1.74-0.28,2.25-0.52v2.72
                c-0.66,0.23-1.88,0.33-3.14,0.33c-3.52,0-4.46-2.72-4.46-5.4v-16.33h-3.57v-2.53h3.57V99.6L344.99,98.75z"
          />
          <path
            d="M367,117.25c0,8.87,5.68,8.87,6.76,8.87c1.55,0,3.8-0.75,5.16-1.97l0.14,3.14c-1.13,0.61-2.72,1.36-5.58,1.36
                c-9.39,0-9.39-9.9-9.39-12.3c0-9.01,4.46-12.3,8.5-12.3c6.95,0,7.65,7.37,7.65,13.19H367z M377.33,114.71
                c0-6.34-2.68-8.12-4.88-8.12c-3.71,0-5.44,5.02-5.44,8.12H377.33z"
          />
          <path
            d="M385.35,111.8c0-2.49-0.14-4.93-0.19-7.42h2.53c0.09,1.08,0.14,1.78,0.19,3.52h0.09c0.56-1.03,2.11-3.85,6.1-3.85
                c6.62,0,6.62,6.62,6.62,8.21v16.05h-2.72v-16.15c0-1.64,0-5.59-4.46-5.59c-3.33,0-5.44,3.14-5.44,6.85v14.88h-2.72V111.8z"
          />
          <path
            d="M407.08,111.8c0-2.49-0.14-4.93-0.19-7.42h2.53c0.05,1.41,0.14,2.82,0.19,3.8h0.09c1.74-3.43,3.75-4.13,6.38-4.13v2.91
                c-4.32,0-6.29,1.88-6.29,6.29v15.07h-2.72V111.8z"
          />
          <path d="M420.08,94.86h3.29v4.22h-3.29V94.86z M420.36,104.39h2.72v23.94h-2.72V104.39z" />
          <path
            d="M442.66,108c-1.08-0.89-2.91-1.22-4.32-1.22c-3.66,0-6.99,2.53-6.99,8.87c0,8.78,4.83,10.28,7.42,10.28
                c1.08,0,3.24-0.33,4.04-1.27l0.24,3.1c-1.17,0.52-2.96,0.89-4.65,0.89c-5.12,0-9.95-3.94-9.95-12.2c0-7.32,3.24-12.39,9.9-12.39
                c1.92,0,3.33,0.38,4.46,0.85L442.66,108z"
          />
          <path
            d="M447.07,93.12h2.72v14.78h0.09c0.47-1.03,2.11-3.85,5.91-3.85c6.62,0,6.62,6.62,6.62,8.21v16.05h-2.72v-16.15
                c0-1.64,0-5.59-4.46-5.59c-3.33,0-5.44,3.14-5.44,6.85v14.88h-2.72V93.12z"
          />
          <path
            d="M485.6,124.29h0.09l5.35-19.9h2.72l-7.84,28.4c-0.89,3.24-1.64,5.96-5.44,5.96c-0.75,0-1.17-0.09-1.83-0.28l0.14-2.77
                c0.47,0.33,1.31,0.52,1.92,0.52c1.31,0,2.02-1.27,2.44-2.77l1.22-4.55l-7.04-24.5h2.91L485.6,124.29z"
          />
          <path
            d="M496.16,116.36c0-6.01,1.97-12.3,8.96-12.3c6.99,0,8.96,6.29,8.96,12.3s-1.97,12.3-8.96,12.3
                C498.13,128.65,496.16,122.36,496.16,116.36z M511.18,116.36c0-1.92,0-9.76-6.05-9.76c-6.05,0-6.05,7.84-6.05,9.76
                c0,1.92,0,9.76,6.05,9.76C511.18,126.12,511.18,118.28,511.18,116.36z"
          />
          <path
            d="M534.56,120.91c0,2.49,0.14,4.93,0.19,7.42h-2.54c-0.09-1.08-0.14-1.78-0.19-3.52h-0.09c-0.28,1.03-2.11,3.85-6.1,3.85
                c-5.21,0-6.62-3.75-6.62-7.56v-16.71h2.72v16.15c0,1.64,0,5.59,4.46,5.59c3.33,0,5.44-3.14,5.44-6.85v-14.88h2.72V120.91z"
          />
          <path
            d="M540.94,111.8c0-2.49-0.14-4.93-0.19-7.42h2.53c0.05,1.41,0.14,2.82,0.19,3.8h0.09c1.74-3.43,3.75-4.13,6.38-4.13v2.91
                c-4.32,0-6.29,1.88-6.29,6.29v15.07h-2.72V111.8z"
          />
          <path d="M565.48,93.12h2.72v35.2h-2.72V93.12z" />
          <path d="M574.78,94.86h3.29v4.22h-3.29V94.86z M575.06,104.39h2.72v23.94h-2.72V104.39z" />
          <path
            d="M585.62,106.92h-3.71v-2.53h3.71v-5.12c0-3.61,0.99-6.71,5.87-6.71c0.94,0,1.6,0.14,2.07,0.23v2.72
                c-0.47-0.19-1.22-0.42-2.16-0.42c-2.39,0-3.05,1.74-3.05,3.66v5.63h4.22v2.53h-4.22v21.4h-2.72V106.92z"
          />
          <path
            d="M598.2,117.25c0,8.87,5.68,8.87,6.76,8.87c1.55,0,3.8-0.75,5.16-1.97l0.14,3.14c-1.13,0.61-2.72,1.36-5.58,1.36
                c-9.39,0-9.39-9.9-9.39-12.3c0-9.01,4.46-12.3,8.5-12.3c6.95,0,7.65,7.37,7.65,13.19H598.2z M608.52,114.71
                c0-6.34-2.68-8.12-4.88-8.12c-3.71,0-5.44,5.02-5.44,8.12H608.52z"
          />
        </g>
      </svg>
    </Link>
  );
};

export default Logo;
