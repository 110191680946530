import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {

  const [modalProps, setModalProps] = useState({});

  return (
    <ModalContext.Provider
      value={{
        modalProps,
        setModalProps
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
