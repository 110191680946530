import { Link } from 'gatsby';
import React, {useEffect, useState} from 'react';
import { FaChevronDown, FaUser } from 'react-icons/fa';

const AccountMenu = ({
  id = 'account-menu',
  startHover,
  endHover,
  handleLogoutClick,
  activeHover
}) => {
 
  let isActiveHover = false;
  if (activeHover === id) {
    isActiveHover = true;
  } 

  return (
    <ul id={id} className="account-menu">
      <li
        className={`menu-dropdown ${isActiveHover ? 'active-hover' : ''}`}
        onMouseEnter={startHover}
        onTouchStart={startHover}
        onMouseLeave={endHover}
        onTouchEnd={endHover}
      >
        <div className="account-icon">
          <span>My Account</span>
          <FaUser />
        </div>
        <FaChevronDown />
        <ul
          className="submenu t-right"
          onMouseEnter={startHover}
          onTouchStart={startHover}
          onMouseLeave={endHover}
          onTouchEnd={endHover}
        >
          <li>
            <Link className="navbar-item" to="/user/saved-artworks">
              <span>Saved Artworks</span>
            </Link>
          </li>
          <li>
            <Link className="navbar-item" to="/user/favourite-artists">
              Favourite Artists
            </Link>
          </li>
          <li>
            <Link className="navbar-item" to="/user/">
              <span>My Account</span>
            </Link>
          </li>
          <li>
            <button onClick={handleLogoutClick} className="navbar-item logout">
              Logout
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
};
export default AccountMenu;
