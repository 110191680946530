import React, { useState } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { Field, Formik } from 'formik';
import { mailchimpSubscribe } from '../../util/helper/mailchimp';
import Ripple from '../fx/ripple/Ripple';

const JoinMailingList = ({ className }) => {
  const [message, setMessage] = useState('');

  const staticData = useStaticQuery(graphql`
    query JoinMailingListStatic {
      allNodeLocation {
        edges {
          node {
            field_address_description
          }
        }
      }
    }
  `);

  const { edges: locations } = staticData.allNodeLocation;

  return (
    <div className="join-mailing-list">
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          mail: '',
          locations: [],
          agreement: false,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.mail) {
            errors.mail = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
          ) {
            errors.mail = 'Invalid email address';
          }
          if (!values.agreement) {
            errors.agreement = 'Required';
          }
          if (!values.locations.length) {
            errors.locations = 'Please select a gallery preference'
          }
          if (!values.first_name) {
            errors.first_name = 'Please enter your name'
          }
          if (!values.last_name) {
            errors.last_name = 'Please enter your last name'
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          setMessage('');

          const { message } = await mailchimpSubscribe(values);

          if (message === 'user subscribed') {
            setMessage(message);
            navigate('/subscribe-successful');
          } else if (
            message ===
            `${values.mail} is already a list member. Use PUT to insert or update list members.`
          ) {
            setMessage(`${values.mail} is already a list member.`);
          } else {
            setMessage(message);
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={className}>
            <h3 className="t-center t-mxlarge t-heading">
              Join Our Email List
            </h3>
            <fieldset>
              <div className="input-item">
                <label className="t-small" htmlFor="first_name">
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  placeholder="First Name"
                />
                <span className="error-message">{errors.first_name && touched.first_name && errors.first_name}</span>
              </div>
              <div className="input-item">
                <label className="t-small" htmlFor="last_name">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  placeholder="Last Name"
                />
                <span className="error-message">{errors.last_name && touched.last_name && errors.last_name}</span>
              </div>
              <div className="input-item">
                <label className="t-small" htmlFor="mail">
                  Email
                </label>
                <input
                  type="email"
                  name="mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mail}
                  placeholder="Your Email"
                />
                <span className="error-message">{errors.mail && touched.mail && errors.mail}</span>
              </div>
              {locations.length ? (
                <>
                  <label className="t-medium" htmlFor="locations">
                    Gallery preference
                  </label>
                  <div className="all-types">
                    {locations.map(({ node: location }) => {
                      return (
                        <div
                          className="control check"
                          key={location.field_address_description}
                        >
                          <label className="checkbox">
                            <Field
                              value={location.field_address_description}
                              type="checkbox"
                              name="locations"
                            />
                            <span className="checkmark"></span>
                            <span className="t-normal">{`${location.field_address_description}`}</span>
                          </label>
                        </div>
                      );
                    })}
                    <span className="error-message">{errors.locations && touched.locations && errors.locations}</span>
                  </div>
                </>
              ) : null}
            </fieldset>
            <fieldset>
              <label className="checkbox">
                I agree to Loch Gallery's{' '}
                <a href="/terms-and-conditions" target="_blank">
                  Terms of Use
                </a>{' '}
                and{' '}
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
                , and to receive emails from Loch Gallery.
                <input
                  type="checkbox"
                  name="agreement"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.agreement}
                />
                <span
                  className="checkmark"
                  onClick={() => (values.agreement = !values.agreement)}
                  onKeyDown={() => (values.agreement = !values.agreement)}
                  role="button"
                  tabIndex={0}
                ></span>
              </label>
            </fieldset>
            <fieldset>
              <Ripple className="form">
                <button
                  type="submit"
                  disabled={isSubmitting || errors.mail || !values.agreement || !values.locations.length}
                >
                  Join Email List
                </button>
              </Ripple>
            </fieldset>
            {message && <p className="t-center">{message}</p>}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default JoinMailingList;
