import React, { useContext, useEffect } from 'react';
import { ModalContext } from '../../context/modal/context';
import Logo from './Logo';

const Modal = ({ id = 'wrapper', children, active, onClose, ...rest }) => {

  const {
    modalProps,
    setModalProps
  } = useContext(ModalContext);

  useEffect(() => {
      if (active === true) {
        setModalProps({
          id,
          children,
          active,
          onClose,
          ...rest
        })
      }
  }, [id, children, active, onClose]);

  return null;
};

export default Modal;
