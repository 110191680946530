import React, { useState, useEffect, useContext } from 'react';

import SubNavbar from './SubNavbar';
import Logo from '../../global/Logo';
import Link from '../../global/Link';
import { navigate } from 'gatsby';
import { LottieToggleAnim } from '../../fx/lottie/lottieAnim';
import Modal from '../../global/Modal';
import { UserContext } from '../../../context/user/context';
import { handleLogout } from '../../../util/auth/drupal';
import JoinMailingList from '../../global/JoinMailingList';
import { FaChevronDown } from 'react-icons/fa';
import AccountMenu from './AccountMenu';
import LoginFlow from '../../global/LoginFlow';
import BecomeAMemberFlow from '../../global/BecomeAMemberFlow';

export default function ({ device, menu, subMenu }) {
  const menuHoveredClass = 'menu-hovered';

  // menu state
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [dropIsActive, setDropIsActive] = useState(false);
  const [activeHover, setActiveHover] = useState(null);
  const [menuHeight, setMenuHeight] = useState(0);
  const [disabled, setDisabled] = useState(true);

  // mailing list state
  const [joinMailingModalActive, setJoinMailingModalActive] = useState(false);

  // user context
  const { state: userState, userLogout } = useContext(UserContext);

  const toggleMenu = (e) => {
    setMenuIsActive(!menuIsActive);
  };

  // disable the menu until the mouse moves
  useEffect(() => {
    const onMoveActivate = () => {
      setDisabled(false);
    };

    window.addEventListener('mousemove', onMoveActivate);

    return () => window.removeEventListener('mousemove', onMoveActivate);
  }, []);

  const startHover = (e, ddNodeDirect = null) => {
    if (disabled) return;

    if (device === 'desktop') {
      // get parent node id
      let ddNode = e.target.parentNode.getAttribute('id');
      // if node is null, check parent of parent
      // this is done to correct for behavior
      // on touch screens (since they target the span)
      if (!ddNode) {
        ddNode = e.target.parentNode.parentNode.getAttribute('id');
      }

      if (ddNodeDirect) {
        ddNode = ddNodeDirect;
      }

      // get dropdown node menu height
      const menuItem = document.getElementById(ddNode);
      if (!menuItem) {
        return;
      }

      const menu = menuItem.querySelector('.submenu');
      if (!menu) return;

      // get the height
      menu.style.height = 'auto';
      menu.style.opacity = 0;
      const { height } = menu.getBoundingClientRect();
      menu.style.height = null;
      menu.style.opacity = null;
      // set the height
      setMenuHeight(height);
      setDropIsActive(true);
      setActiveHover(ddNode);
    }
  };

  const endHover = (e) => {
    setDropIsActive(false);
    setMenuHeight(0);
    setActiveHover(null);
  };

  // drupal menu helper function
  // originally from: https://github.com/xaviemirmon/gatsby_drupal_menus/blob/master/index.js
  const createMenuHierarchy = (menuData) => {
    let tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = menuData.length; i < len; i++) {
      arrElem = menuData[i].node;
      if (arrElem.enabled === true) {
        mappedArr[arrElem.drupal_id] = arrElem;
        if (
          arrElem.drupal_parent_menu_item != null &&
          arrElem.drupal_parent_menu_item.includes(arrElem.bundle)
        ) {
          let stripped_drupal_id = arrElem.drupal_parent_menu_item.replace(
            arrElem.bundle + ':',
            ''
          );
          mappedArr[arrElem.drupal_id].drupal_parent_menu_item =
            stripped_drupal_id;
        }
        mappedArr[arrElem.drupal_id]['children'] = [];
      }
    }

    for (let id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.drupal_parent_menu_item) {
          mappedArr[mappedElem.drupal_parent_menu_item]['children'].push(
            mappedElem
          );
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  };

  const buildLink = (link, children = false) => {
    if (!link.external && link.link.uri.includes('internal:')) {
      return (
        <div className="navbar-item">
          {children ? <FaChevronDown /> : null}
          <Link
            activeClassName="active"
            path={link.link.uri.replace('internal:', '')}
          >
            {link.title}
          </Link>
        </div>
      );
    } else {
      return (
        <div className="navbar-item">
          {children ? <FaChevronDown /> : null}
          <a href={link.link.uri} target="_blank" className={'external'}>
            {link.title}
          </a>
        </div>
      );
    }
  };

  const generateMenu = (menuLinks) => {
    let menu;

    if (menuLinks) {
      menu = createMenuHierarchy(menuLinks.edges);
      menu = buildMenu(menu);
    }
    return menu;
  };

  const buildMenu = (menuArray) => {
    if (!menuArray) {
      return;
    }
    let menu = [];
    for (let item in menuArray) {
      if (menuArray[item].children.length !== 0) {
        let id = `dropdown_${menuArray[item].drupal_id}`;
        let isActiveHover = id === activeHover ? true : false;
        menu.push(
          <li
            key={menuArray[item].drupal_id}
            onMouseEnter={(e) => startHover(e, id)}
            onMouseLeave={endHover}
            onTouchStart={(e) => startHover(e, id)}
            onTouchEnd={endHover}
            className={`menu-dropdown ${isActiveHover ? 'active-hover' : ''}`}
            id={id}
          >
            {buildLink(menuArray[item], true)}
            <ul className="submenu">{buildMenu(menuArray[item].children)}</ul>
          </li>
        );
      } else {
        menu.push(
          <li key={menuArray[item].drupal_id}>{buildLink(menuArray[item])}</li>
        );
      }
    }

    return menu;
  };

  const handleLogoutClick = () => {
    const logout = async () => {
      userLogout();
      handleLogout();
      navigate('/');
    };

    logout();
  };

  const toggleJoinMailingModal = (e) => {
    setJoinMailingModalActive(!joinMailingModalActive);
  };

  return (
    <header className={`lt-nav-wrapper ${menuIsActive ? 'is-active' : ''}`}>
      <div
        className={`lt-nav-container ${menuIsActive ? 'is-active' : ''} ${
          dropIsActive ? 'is-dropdown' : ''
        }`}
      >
        <div className="lt-Logo">
          <Logo device={device} link={true} />
        </div>
        <div className="lt-Navigation">
          <div className="navbar-mobile-toggle" onClick={toggleMenu}>
            <LottieToggleAnim
              animation="menu-icon"
              toggleAnimation={menuIsActive}
            />
          </div>
          <nav className="navbar inline">
            <ul>{generateMenu(menu)}</ul>
            {!userState.user.isLoggedIn ? null : (
              <nav className="mobile-nav-bar">
                <AccountMenu
                  id="account-menu-mobile"
                  startHover={startHover}
                  endHover={endHover}
                  handleLogoutClick={handleLogoutClick}
                  activeHover={activeHover}
                />
              </nav>
            )}
            <div className="mobile-nav-bar buttons">
              <span className="ripple-wrapper join-mailing-list-toggle">
                <button
                  className="button btn-outline"
                  onClick={toggleJoinMailingModal}
                >
                  Join Our Email List
                </button>
              </span>
              <Modal
                active={joinMailingModalActive}
                onClose={toggleJoinMailingModal}
              >
                <JoinMailingList className="join-mailing-list" />
              </Modal>
              <span className="ripple-wrapper">
                <LoginFlow className="button btn-outline">Login</LoginFlow>
              </span>
              <span className="ripple-wrapper btn-become-a-member">
                <BecomeAMemberFlow
                  className="button"
                  onClick={toggleJoinMailingModal}
                >
                  Become a member
                </BecomeAMemberFlow>
              </span>
            </div>
          </nav>
        </div>
        <div className="lt-Buttons">
          {!userState.user.isLoggedIn ? (
            <React.Fragment>
              <span className="ripple-wrapper join-mailing-list-toggle">
                <button
                  className="button btn-outline"
                  onClick={toggleJoinMailingModal}
                >
                  Join Our Email List
                </button>
              </span>
              <Modal
                active={joinMailingModalActive}
                onClose={toggleJoinMailingModal}
              >
                <JoinMailingList className="join-mailing-list" />
              </Modal>
              <span className="ripple-wrapper">
                <LoginFlow className="button btn-outline">Login</LoginFlow>
              </span>
              <span className="ripple-wrapper btn-become-a-member">
                <BecomeAMemberFlow
                  className="button"
                  onClick={toggleJoinMailingModal}
                >
                  Become a member
                </BecomeAMemberFlow>
              </span>
            </React.Fragment>
          ) : (
            <nav className="navbar inline">
              <AccountMenu
                startHover={startHover}
                endHover={endHover}
                handleLogoutClick={handleLogoutClick}
                activeHover={activeHover}
              />
            </nav>
          )}
        </div>
      </div>
      {subMenu ? <SubNavbar menu={menu} subMenu={subMenu} /> : null}
      <div
        className={`menu-dd-container ${menuIsActive ? 'is-active' : ''}`}
        style={{ height: `${menuHeight}px` }}
      ></div>
    </header>
  );
}
