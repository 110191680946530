import React, { useEffect, useState, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import Helmet from 'react-helmet'

import Navbar from './navbar/Navbar'
import Footer from './Footer'
//import { ScrollProvider } from '../fx/scroll-position/ScrollContainer'
import NoScript from '../helper/NoScript'
import useAuth  from '../../util/hooks/useAuth'
import '../../assets/main.sass'
import { UserContext } from '../../context/user/context'
import ScrollContext from '../../context/scrollBehavior'
import ModalBase from '../global/ModalBase';
import { ModalProvider } from '../../context/modal/context'

const Layout = ({children, device, setDevice, noindex, menu, subMenu, footerMenu, locations, className}) => {
  useAuth();
  const { state } = useContext(UserContext);
  const { user } = state;
  const SCROLL_CONTEXT = useContext(ScrollContext);
  let scrollDirection = 'up';
  if (SCROLL_CONTEXT) {
    scrollDirection = SCROLL_CONTEXT.scrollDirection;
  }

  // determine device and pass device
  // back up to page component
  const isDesktop = useMediaQuery({query: '(min-width: 1025px)'})
  const isTablet = useMediaQuery({query: '(min-width: 767px) and (max-width: 1024px)'})
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  let viewDevice = device
  switch(true){
      case isDesktop:
          viewDevice = 'desktop'
      break
      case isTablet:
          viewDevice = 'tablet'
      break
      case isMobile: default:
          viewDevice = 'mobile'
      break
  }

  // if we're server side, always render mobile
  // desktop can re-render on app state hydration
  if (typeof window === 'undefined') {
    viewDevice = 'mobile'
  }

  // update the device
  useEffect(() => {
    setDevice(viewDevice)
  },[viewDevice, device]);

    // check if this should be indexed
    // const indexmeta = noindex ? <meta name="robots" content="noindex" /> : null
    // const indexmeta = <meta name="robots" content="noindex" />

    return (
      <ModalProvider>
        <div className="gatsby-layout-container">
          <div className={`${scrollDirection ? `scrolled ${scrollDirection}` : ''} ${user.isLoggedIn ? 'user-logged-in' : ''}`}>
              <NoScript />
              <Navbar device={device} scrollDirection={scrollDirection} menu={menu} subMenu={subMenu} />
              <div className={`layout layout-${device} ${className ? className : ''}`} device={device} key="layout">
                {children}
              </div>
          </div>
          <Footer device={device} locations={locations} menu={footerMenu} />
          <ModalBase />
        </div>
      </ModalProvider>
    )
}

export default Layout