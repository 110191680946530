import axios from 'axios';
import { baseUrl } from '../auth/drupal';

export const mailchimpSubscribe = async (data) => {
  const config = {
    method: 'post',
    url: `${baseUrl}/jsonapi/subscribe-mailchimp`,
    data,
  };

  return await axios(config)
    .then((res) => res.data)

    .catch((e) => {
      console.log(e);
    });
};

export const mailchimpUnsubscribe = async ({ mail }) => {
  const data = {
    mail,
  };

  const config = {
    method: 'post',
    url: `${baseUrl}/jsonapi/unsubscribe-mailchimp`,
    data,
  };

  return await axios(config)
    .then((res) => res.data)
    .catch((e) => {
      console.log(e);
    });
};
