import React from 'react'
class Ripple extends React.Component {
  
  constructor(props) {
    super(props) 

    this.state = {
        ripple: false,
        rippleStart: false,
    }

    this.time = 1000
  }

  
  componentDidUpdate() {
    const { ripple } = this.state
    if (ripple) {
       this.startTimer()
    }
  }

  startTimer() {
      
      this.clock = setTimeout(() => {
        this.setState({
            ripple: false
        })
      }, this.time)

  }
 

  buttonStartRipple = (e) => {
    const offset = e.target.getBoundingClientRect()

    this.setState({
        ripple: {
            x: (e.clientX - offset.left) - offset.width / 2,
            y: (e.clientY - offset.top) - offset.height / 2,
            size: Math.max(offset.width, offset.height)
        }
    })
  }
  
  render () {
    const { children, className } = this.props
    // set up ripple effect
    const { ripple } = this.state
    let rippleFX = null
    if (ripple) {
        //console.log('ripple', ripple)
        const rippleStyle = {
          top: `${ripple.y}px`,
          left: `${ripple.x}px`,
          height: `${ripple.size}`,
          width: `${ripple.size}`,
        }
        rippleFX = <span className="ripple" style={rippleStyle}></span>
    }

    return (
        <span className={`ripple-wrapper ${className ? className : ''}`} onClick={this.buttonStartRipple}>
            {rippleFX}
            {children}
        </span>
    )
  }
};

export default Ripple;