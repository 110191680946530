import React from 'react'

const NoScript = ({}) => {

    const supportsCSSGrid = () => {
        if (typeof window !== 'undefined') {
            // we're checking support for css grid and ms-grid
            var el = document.createElement('div');
            var supports_grid = typeof el.style.grid === 'string';
            var supports_msgrid = typeof el.style.msGridColumns === 'string';
            return supports_grid || supports_msgrid;
        }
        return true
    }

    return (
        <React.Fragment>
            <noscript>
                <div className="browser-warning">Your browser is not supported by this website. You must have javascript enabled.</div>
            </noscript>
            <div>
                {!supportsCSSGrid() ? (
                    <div className="browser-warning">Your browser is not supported by this website. Display may be sub-optimal. Try using a different browser</div>
                ) : null}
            </div>
        </React.Fragment>
    )
}

export default NoScript