import React, { createRef } from "react";
export class LottieEntranceAnim extends React.Component {
  
  constructor(props) {
    super(props) 

    this.state = {
      anim: false,
      animState: false,
    }

    this.mount = false
  }

  componentDidMount() {
    this.animationContainer = createRef()
  }

  componentDidUpdate() {
    const { device, visibility } = this.props
    const { anim, animState } = this.state

    if (device === 'desktop' && anim === false && this.mount === false) {
      this.mount = true
      this.createLottie()
    }

    const play = visibility === 'visible' ? true : false
    if (animState !== play) {
      this.setState({animState: play})
    }
  }

  createLottie() {
    const { animation } = this.props
    import('lottie-web')
      .then((lottie) => {
        import(`../../../assets/media/${animation}`)
        .then((json) => { 
          const anim = lottie.loadAnimation({
            container: this.animationContainer.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: json
          })

          this.setState({
            anim: anim
          })
        })
      })
      .catch((e) => alert(e))
  }
  
  render () {
    
    const { anim, animState } = this.state
    
    if (animState && anim) {
      anim.play()
    }

    return (
      <div className="animation-container" ref={this.animationContainer} /> 
    )
  }
};

export class LottieToggleAnim extends React.Component {
  
  constructor(props) {
    super(props) 

    this.state = {
      animate: false,
      lottie: false,
      svg: null,
      transition: false
    }

    this.mount = false
  }

  componentDidMount() {
    this.animationContainer = createRef()
    this.importSVG()
  }

  // check should component update
  // only update if the toggle animation param has changed
  // and if lottie is mounted
  // this is to prevent too many animations from being initiated
  shouldComponentUpdate({toggleAnimation}, {animate, lottie}) {
    if (!lottie) {
      return true
    }
    if (toggleAnimation !== animate) {
      return true
    }
    return false
  }

  componentDidUpdate() {
    const { toggleAnimation } = this.props
    const { animate } = this.state
    
    if (toggleAnimation !== animate) {
      this.setState({
        animate: toggleAnimation
      })
    }
  }

  importSVG() {
    const { animation } = this.props
    import(`../../../assets/media/${animation}.svg`)
      .then((svg) => { 
        this.setState({
          svg: <img src={svg.default} />
        })
      })
  }

  prefetch = (e) => {
    const { lottie } = this.state

    if (!lottie && !this.mount) {
      this.mount = true
      this.createLottie()
    }
  }

  createLottie() {
    const { animation } = this.props
    import('lottie-web')
      .then((lottie) => {
        // import asset by name from the media folder
        import(`../../../assets/media/${animation}.json`)
        .then((json) => { 
          const anim = lottie.loadAnimation({
            container: this.animationContainer.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: json
          })

          this.setState({
            lottie: anim,
            transition: true,
          })
        })
      })
      .catch((e) => alert(e))
  }
  
  render () {
    const { lottie, svg, transition, animate } = this.state

    // if we're toggling on animations
    // and lottie anim is loaded
    if (!animate && lottie && this.mount) {
      lottie.setDirection(1)
      lottie.setSpeed(1)
      lottie.goToAndStop(0)
      lottie.play()
    }
    
    // if we're toggling off animations
    // and lottie anim is loaded
    if (animate && lottie && this.mount) {
      lottie.setDirection(-1)
      lottie.setSpeed(2)
      lottie.play()
    }

    return (
      <div 
        className={`animation-container ${transition ? 'transition' : ''}`} 
        ref={this.animationContainer}
        onMouseEnter={this.prefetch}
      >{svg}</div> 
    )
  }
};