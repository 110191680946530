import React from 'react'

import Link from '../../global/Link'

export default class SubNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {mainItem: null};
    }

  // drupal menu helper function
  // originally from: https://github.com/xaviemirmon/gatsby_drupal_menus/blob/master/index.js
  createMenuHierarchy = (menuData) => {
    let tree = [],
       mappedArr = {},
       arrElem,
       mappedElem

    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = menuData.length; i < len; i++) {
      arrElem = menuData[i].node
      if (arrElem.enabled === true) {
        mappedArr[arrElem.drupal_id] = arrElem
        if (arrElem.drupal_parent_menu_item != null && arrElem.drupal_parent_menu_item.includes(arrElem.bundle)) {
          let stripped_drupal_id = arrElem.drupal_parent_menu_item.replace(arrElem.bundle + ':', '')
          mappedArr[arrElem.drupal_id].drupal_parent_menu_item = stripped_drupal_id
        }
        mappedArr[arrElem.drupal_id]['children'] = []
      }
    }

    for (let id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id]
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.drupal_parent_menu_item) {
          mappedArr[mappedElem.drupal_parent_menu_item]['children'].push(mappedElem)
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem)
        }
      }
    }
    return tree
  }

  buildLink = (link) => {
    if(!link.external && link.link.uri.includes('internal:')) {
      return ( <Link activeClassName="active" path={link.link.uri.replace('internal:', '')} className='navbar-item'>
        {link.title}
      </Link>)
    } else {
      return ( <a href={link.link.uri_alias} className={'external navbar-item'}>
        {link.title}
      </a>)
    }
  }

  generateMenu = (menuLinks) => {
    let menu

    if (menuLinks) {
      menu = this.createMenuHierarchy(menuLinks.edges)
      menu = this.getCurrentChildren(menu)
      menu = this.buildMenu(menu)
    }
    return menu
  }

  getCurrentChildren = (menu) => {
      const { subMenu } = this.props;

      return menu.filter((item) => {
          if (item.title.toLowerCase() === subMenu.title) {
              let foundItem = item;
              // update state
              if (subMenu.title === 'historical' || subMenu.title === 'contemporary') {
                foundItem = {}
                foundItem.link = {}
                if (subMenu.title === 'historical') {
                  // set as contemporary
                  foundItem.link.uri = '/contemporary/'
                  foundItem.title = 'Contemporary'
                }
                if (subMenu.title === 'contemporary') {
                  // set as historical
                  foundItem.link.uri = '/historical/'
                  foundItem.title = 'Historical'
                }
              }
              if (!this.state.mainItem) {
                this.setState({
                    mainItem: foundItem
                })
              }
              return foundItem
          }
      })
  }

  buildMenu = (menuArray) => {
    if(!menuArray)  {
      return
    }
    let menu = []
    for(let item in menuArray[0].children) {
        menu.push(<li key={menuArray[0].children[item].drupal_id}>{this.buildLink(menuArray[0].children[item])}</li>)
    }
    return menu
  }

  hasSecondaryMenuButton = () => {
    const { mainItem } = this.state;

    if (mainItem && mainItem.title) {
      switch(mainItem.title) {
        case 'About':
        case 'Exhibitions':
          return false;
      }
    }
    return true;
  }

  render() {
    const { device, menu, subMenu } = this.props
    const { mainItem } = this.state
    const hasSubMenuButton = this.hasSecondaryMenuButton();

    return (
        <div className="sub-menu-wrapper">
            <nav className={`navbar inline ${!hasSubMenuButton ? 'no-button' : ''}`}>
                <ul>
                {this.generateMenu(menu)}
                </ul>
            </nav>
            {subMenu.extra && mainItem && hasSubMenuButton ? (
                <div className="navbar-extra-link">
                    <Link
                        button
                        className={`btn-outline`}
                        path={mainItem.link.uri.replace('internal:', '')}
                        linkkey={`button1`}
                        device={device}
                    >Go To {mainItem.title}</Link>
                </div>
            ) : null}
        </div>
    )
  }
}
