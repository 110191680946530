import React, { useState, useEffect, useContext } from 'react'
import { drupalPasswordReset } from '../../util/auth/register'
import Loading from '../../assets/media/Rhombus.gif'
import { navigate } from 'gatsby';
import { UserContext } from '../../context/user/context';

export default function PasswordReset({onLogin}) {

    const [mail, setEmailField] = useState('');
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState(null);

    const handleFormSubmission = (e) => {
        e.preventDefault();
        if (!submit) {
            setSubmit(true);
            setLoading(true);
        }
    }

    const validateFormFields = () => {

        if (!submit) return true;

        let errors = {}

        if (mail.length === 0) {
            // email not long enough
            errors.mail = 'You must provide an email'
        }
        if (Object.keys(errors).length) {
            setError(errors);
            setSubmit(false);
            setLoading(false);
            return false;
        }
        return true;
    }

    // register on submit
    useEffect(() => {

        async function fetchPassReset() {

            // invoke early
            setSubmit(false);

            // registration request
            const res = await drupalPasswordReset(mail);

            // there's an error, display it
            if (res.error) {
                setError(extractHumanFriendlyError(res.msg));
                setSuccess(null);
            }
            // no error, do stuff
            else {
                setSuccess(true);
                setError({});
            }

            setLoading(false);
        }

        // validate form fields first
        if (validateFormFields()) {

            if (submit) {
                // if submit, fetch
                fetchPassReset();
            }

        }

    }, [submit, mail, validateFormFields]);

    // take the response from drupal and make it more...
    // useful.
    const extractHumanFriendlyError = (response) => {
        let friendlyErrors = {};
        let errors = response.replace("Unprocessable Entity: validation failed.", "").split("\n");
        for (const i in errors) {
            let error = errors[i];

            const mail_error = 'mail:';

            switch(true){
                case error.includes(mail_error):
                    friendlyErrors.mail = error.replace(mail_error, "")
                break;
            }
        }

        // check if we found any identifiable errors
        // if not, return a general error that
        // we can use to pass info along to the
        // users
        if (Object.keys(friendlyErrors).length) {
            return {...friendlyErrors, general: "Please correct the following errors"};
        } else {
            // return a general error
            return {
                general: response
            }
        }
    }

    return (
        <div className="login-form-section">
            <h3 className="t-center t-mxlarge t-heading">Reset your Password</h3>

            {error.general && (
                <div className="callout error">
                    {error.general}
                </div>
            )}

            <form
                onSubmit={handleFormSubmission}
            >
                <div className="input-item">
                    <label className="t-small" htmlFor="email">
                        Email
                    </label>
                    <input
                        value={mail}
                        autoComplete="email"
                        id="email"
                        name="email"
                        onChange={(e) => setEmailField(e.target.value)}
                        type="email"
                        placeholder="Your Email"
                        data-error={error.mail && (true)}
                    />
                    {error.mail && (<span className="error">{error.mail}</span>)}
                </div>

                <input type="submit" data-loading={loading && (true)} value={loading ? 'Loading...' : 'RESET YOUR PASSWORD'} />
                <div className="form-element center">
                    <p>Already have an account? <a onClick={onLogin} onKeyDown={onLogin} role="button" tabIndex={0}>Log in</a></p>
                </div>
                <div className="form-element center">
                    <small>
                        This site is protected by reCaptcha and the Google Privacy Policy Terms of Service apply.
                    </small>
                </div>
            </form>

            {success && (
                <div className="callout success">
                    Further instructions have been sent to your email
                </div>
            )}
        </div>
    )
}