import React, { useContext } from 'react';
import { ModalContext } from '../../context/modal/context';
import Logo from './Logo';

const ModalBase = () => {

  const {
    modalProps,
    setModalProps
  } = useContext(ModalContext);

  const {active, id, children, onClose, ...rest} = modalProps;

  const closeModal = () => {
      onClose();
      let newModalProps = {...modalProps};
      newModalProps.active = false;
      setModalProps(newModalProps);
  }

  if (!active || !children) return null;

  return (
    <div {...rest}>
      <div
        className={`modal-overlay ${active ? 'is-active' : ''}`}
        onClick={closeModal}
      ></div>
      <div
        id={`modal-window-${id}`}
        className={`modal ${active ? 'is-active' : ''}`}
      >
        <div className="modal-background"></div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={closeModal}
        >
          close
        </button>
        <div className="modal-content">
          <div className="logo spacer">
            <Logo link={false} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalBase;
