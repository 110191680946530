import React, {useState, useContext} from 'react'

import {
    FaInstagram,
    FaFacebookF,
    FaTwitter,
  } from 'react-icons/fa';

import {Link} from 'gatsby'
import CompLink from '../global/Link'
import Logo from '../global/Logo'
import Modal from '../../components/global/Modal'
import JoinMailingList from '../../components/global/JoinMailingList'
import { UserContext } from '../../context/user/context';

const Footer = ({device, locations, menu}) => {
    const { state } = useContext(UserContext);
    const { user } = state;
    const [modalOpen, setModalOpen] = useState(false);
    const createMenuHierarchy = (menuData) => {
        let tree = [],
            mappedArr = {},
            arrElem,
            mappedElem

        // First map the nodes of the array to an object -> create a hash table.
        for (let i = 0, len = menuData.length; i < len; i++) {
            arrElem = menuData[i].node
            if (arrElem.enabled === true) {
                mappedArr[arrElem.drupal_id] = arrElem
            }
        }

        for (let id in mappedArr) {
            if (mappedArr.hasOwnProperty(id)) {
                mappedElem = mappedArr[id]
                tree.push(mappedElem)
            }
        }

        return tree
    }

    const buildLink = (link) => {
        if (!link.external && link.link.uri.includes('internal:')) {
            return (
                <CompLink activeClassName="active" path={link.link.uri.replace('internal:', '')}>
                    {link.title}
                </CompLink>
            )
        } else {
            return (
                <a href={link.link.uri_alias} className={'external'}>
                    {link.title}
                </a>
            )
        }
    }

    const generateMenu = (menuLinks) => {
        let menu

        if (menuLinks) {
            menu = createMenuHierarchy(menuLinks.edges)
            menu = buildMenu(menu)
        }

        return menu
    }

    const buildMenu = (menuArray) => {
        if (!menuArray) return
        let menu = []

        for (let item in menuArray) {
            menu.push(<li key={menuArray[item].drupal_id}>{buildLink(menuArray[item])}</li>)
        }

        return menu
    }

    return (
        <footer className="footer">
            <div className="container">
                <div className="columns">
                    <div className="column is-4 footer-logo">
                        <div id="logo-footer" className="columns is-multiline is-centered">
                            <div className="colum logo">
                                <Logo device={device} link={false} />
                            </div>
                            <div className="colum join">
                                {
                                    user.isLoggedIn ? null :
                                    <React.Fragment>
                                        <button
                                            className="button"
                                            onClick={() => setModalOpen(true)}
                                        >
                                            Join Our Email List
                                        </button>
                                        <Modal active={modalOpen} onClose={() => setModalOpen(false)}>
                                            <JoinMailingList className="join-mailing-list" />
                                        </Modal>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="column is-2 footer-menu-links">
                        <ul className="footer-links-list">
                            {generateMenu(menu)}
                        </ul>
                        <div className="social"><Link className="button btn-outline" to="/contact">Contact</Link></div>
                    </div>
                    <div className="column is-6 footer-contact">
                        <div className="columns locations">
                            {locations ? locations.map(({node: location}) => {
                                if (location.field_address_description) {
                                    return (
                                        <div className="column" key={`location_${location.title}`}>
                                            <h2>{location.field_address_description}</h2>
                                            <h4><a href={"tel:"+location.field_location_phone_number}>{location.field_location_phone_number}</a></h4>
                                            {/* `<a href="tel:${ocation.field_location_phone_number}">${ocation.field_location_phone_number}</a>` */}
                                            <p>
                                                {location.field_address_short}<br />
                                                {location.field_office_hours_description}
                                            </p>
                                        </div>
                                    )
                                }
                            }) : null}
                        </div>
                        <div className="social-icons">
                            <a href="https://www.instagram.com/loch_gallery/" target="_blank" rel="noreferrer"><FaInstagram /></a>
                            <a href="https://www.facebook.com/lochgallery" target="_blank" rel="noreferrer"><FaFacebookF /></a>
                            <a href="https://twitter.com/LochGallery" target="_blank" rel="noreferrer"><FaTwitter /></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
