import { baseUrl } from './cms';

const registerUrl = `${baseUrl}/user/register?_format=json`;
const verifyContactUrl = `${baseUrl}/register/`;
const validateContactUrl = `${baseUrl}/validateRegistration/`;
const confirmValidateUrl = `${baseUrl}/jsonapi/existing-email-validate-hash/`;
const passwordResetUrl = `${baseUrl}/user/lost-password?_format=json`
const passwordChangeUrl = `${baseUrl}/user/lost-password-reset?_format=json`
//const CSRFUrl = `${baseUrl}/rest/session/token`

/**
 * drupalRegister
 * ---
 * Register a new user in Drupal
 * 
 * Provide user registration fields
 * @param mail - username / email
 * @param fname - first name
 * @param lname - last name
 * @param pass
 * @returns {Promise<void>}
 * Returns a promise that resolves to JSON response from Drupal.
 */
export const drupalRegister = async ({mail, pass, fname, lname}) => {

    const user_contact_json = JSON.stringify({
        email: mail,
        field_first_name: fname,
        field_last_name: lname
    });
    const response = doFetch({
        url: registerUrl,
        format: 'json',
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            'name': [{'value': mail}],
            'mail': [{'value': mail}],
            'pass': [{'value': pass}],
            'field_user_contact_json': [{'value': user_contact_json}],
            'field_first_name': [{'value': fname}],
            'field_last_name': [{'value': lname}]
        }
    })
    
    // check the reponse and return
    return response.then(async (value) => {
        // valid response
        if (value.uid) {
            // once registered, validate the contact node
            const validate = await validateUserContact(mail);
            if (validate.error === true) {
                return validate;
            }
            // if we're here, there's no valid contact node.
            // resume registration
            await verifyUserContact(mail, pass);
            await verifyUserContact(mail, pass);
            return { error: false }
        }
        // invalid response
        if (value.message) {
            return {error: true, msg: value.message};
        }
        // fallback
        return {error: true, msg: 'Something went wrong on our end, sorry about that. Please refresh the page and try again.'}
    });
};

/**
 * validateUserContact
 * ---
 * Validate the existence of a contact node
 * if one exists, submit an email for confirmation
 * 
 * if none exists, proceed.
 * 
 * Provide user registration fields
 * @param mail - user email
 * @returns {Promise<void>}
 * Returns a promise that resolves to JSON response from Drupal.
 */
const validateUserContact = async (mail) => {

    const response = doFetch({
        url: `${validateContactUrl}${mail}`,
        format: 'json',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    
    // check the reponse and return
    return response.then((value) => {
        // validate response
        if (typeof value === "boolean" && value === true) {
            return { error: false };
        }
        // no response, we can proceed
        return { error: true, msg: "confirmation_notice: " + String(value) }
    });
};

/**
 * confirmAccount
 * ---
 * Send a post request to drupal
 * to confirm this account, and assign the
 * existing contact node to the newly registered
 * user that was created in the previous steps
 * 
 * if confirmation fails, report an error
 * 
 * @param hash - the hash provided from the url
 * @returns {Promise<void>}
 * Returns a promise that resolves to JSON response from Drupal.
 */
export const confirmAccount = (hash) => {

    const response = doFetch({
        url: `${confirmValidateUrl}${hash}`,
        format: 'json',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    
    // check the reponse and return
    return response.then((value) => {
        
        if (value.status === "hash not found") {
            return { error: true, msg: "There was an issue validating the link for your account. Please resubmit and try again."}
        }

        if (value.email) {
            return { error: false };
        }

        // no response, we can proceed
        return { error: true, msg: "We encountered an issue when validating your account. Please resubmit and try again." }
    });
}

/**
 * verifyUserContact
 * ---
 * Verify the new User / Contact Node
 * 
 * Provide user registration fields
 * @param mail - username / email
 * @param fname - first name
 * @param lname - last name
 * @param pass
 * @returns {Promise<void>}
 * Returns a promise that resolves to JSON response from Drupal.
 */
export const verifyUserContact = async (username, password) => {

    const response = doFetch({
        url: `${verifyContactUrl}${username}`,
        format: 'json',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Basic " + btoa(username + ":" + password)
        }
    })
    
    // check the reponse and return
    return response.then((value) => {
        // valid response
        if (value) {
            return { error: false }
        }
        // fallback
        return {error: true, msg: 'Something went wrong on our end, sorry about that. Please refresh the page and try again.'}
    });
};

/**
 * drupalPasswordReset
 * ---
 * Request a password reset for email from drupal
 * 
 * Provide user registration fields
 * @param mail - username / email
 * @returns {Promise<void>}
 * Returns a promise that resolves to JSON response from Drupal.
 */
 export const drupalPasswordReset = async (mail) => {

    const response = doFetch({
        url: passwordResetUrl,
        format: 'json',
        credentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            'mail': mail,
        }
    })
    
    // check the reponse and return
    return response.then(async (value) => {
        // valid response
        if (value.message) {
            return { error: false, msg: value.message }
        }
        // fallback
        return {error: true, msg: value}
    });
};

/**
 * changePassword
 * ---
 * Send a post request to drupal
 * that will change the user password using the
 * token provided from email/url
 * 
 * if the action fails, report an error
 * 
 * @param user - the username of the password we are trying to change
 * @param temppass - the temporary password provided
 * @param newpass - the new password we're changing to
 * @returns {Promise<void>}
 * Returns a promise that resolves to JSON response from Drupal.
 */
 export const changePassword = ({user, temppass, newpass}) => {

    const response = doFetch({
        url: `${passwordChangeUrl}`,
        format: 'json',
        credentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
        body: {
            'name': user,
            'temp_pass': temppass,
            'new_pass': newpass
        }
    })
    
    // check the reponse and return
    return response.then((value) => {

        console.log('the value', value);

        if (value.message === "No valid temp password request.") {
            return { error: true, msg: "There was an issue validating your password reset. Please resubmit and try again"}
        }

        if (value.message === "Your New Password has been saved please log in.") {
            return { error: false };
        }
  
        return { error: true, msg: "There was an issue validating your password reset. Please resubmit and try again"}
    });
  }

/**
 * doFetch
 * ---
 * a helper method for performing async
 * fetch requests and returning the result
 * 
 * @param url the url to fetch
 * @param body the body content of the fetch request
 * @returns a promise containing the reponse data, OR false
 */
export const doFetch = async ({url, headers = false, body = false, mode = "cors", method = 'POST', format = 'json', credentials = false}) => {
    
    const ERROR_MSG = `/src/util/auth/doFetch error: There was an error attempting to fetch from ${url}`
    
    try {
        let fetchObj = 
        {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
        }

        if (headers) {
            fetchObj.headers = headers;
        }

        if (body) {
            fetchObj.body = JSON.stringify(body);
        }

        if (credentials) {
            fetchObj.credentials = 'include';
        }

        fetchObj.mode = mode;

        const response = await fetch(
            url,
            fetchObj
        );
        
        if (!response.ok) {
            console.error(ERROR_MSG, response.status);
        }

        switch(format) {
            case 'text':
                return await response.text();
            case 'json':
                return await response.json();
        }

    } catch(e) {
        console.error(ERROR_MSG, e)
        return false;
    }
}